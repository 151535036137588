import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

import hc037 from "../images/hc037.jpg"
import hccartridge from "../images/hccartridge.jpg"
import hccoffeehearty from "../images/hccoffeehearty.jpg"
import hcdriedmango from "../images/hcdriedmango.jpg"
import hcgummybears from "../images/hcgummybears.jpg"
import hchealingointment from "../images/hchealingointment.jpg"
import hchoneygummies from "../images/hchoneygummies.jpg"
import hchoneysticks from "../images/hchoneysticks.jpg"
import hchoneysticksfruity from "../images/hchoneysticksfruity.jpg"
import hclavenderlotion from "../images/hclavenderlotion.jpg"
import hcmixedpartypack from "../images/hcmixedpartypack.jpg"
import hcpaincream from "../images/hcpaincream.jpg"
import hcpettreats from "../images/hcpettreats.jpg"
import hcpettreats2 from "../images/hcpettreats2.jpg"
import hcpettreats3 from "../images/hcpettreats3.jpg"
import hcpettreats4 from "../images/hcpettreats4.jpg"
import hcpopcorn from "../images/hcpopcorn.jpg"
import hcsunscreen from "../images/hcsunscreen.jpg"
import hcveganjerky from "../images/hcveganjerky.jpg"

const LabReportsPage = () => {
  return (
    <Layout>
      <SEO title="Lab Reports" />
      <div className="page-headline mt-5 about-bg">
        <div className="container">
          <div className="section-heading text-center ">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h1 className="font-weight-bold text-uppercase text-white mb-4">
                  <strong>LAB REPORTS</strong>
                </h1>
                <p className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-section" id="">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="container">
                <div className="content text-center">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col">
                        <div class="tabs text-left">
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd1"
                              name="rd"
                            />
                            <label class="tab-label" for="rd1">
                              HEAVENLY CANDY 037 1000mg tincture- Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hc037} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd2"
                              name="rd"
                            />
                            <label class="tab-label" for="rd2">
                              HEAVENLY CANDY cartridge - Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hccartridge} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd3"
                              name="rd"
                            />
                            <label class="tab-label" for="rd3">
                              HEAVENLY CANDY coffee hearty blend roast 200mg-
                              Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hccoffeehearty} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd4"
                              name="rd"
                            />
                            <label class="tab-label" for="rd4">
                              HEAVENLY CANDY dried fruit mango - Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcdriedmango} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd5"
                              name="rd"
                            />
                            <label class="tab-label" for="rd5">
                              HEAVENLY CANDY Gummy Bears6Aug2019 - Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcgummybears} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd6"
                              name="rd"
                            />
                            <label class="tab-label" for="rd6">
                              HEAVENLY CANDY healing ointment - Potency
                            </label>
                            <div class="tab-content">
                              <img
                                className="img-fluid"
                                src={hchealingointment}
                              />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd7"
                              name="rd"
                            />
                            <label class="tab-label" for="rd7">
                              HEAVENLY CANDY honey gummies - Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hchoneygummies} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd8"
                              name="rd"
                            />
                            <label class="tab-label" for="rd8">
                              HEAVENLY CANDY Honey Sticks - Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hchoneysticks} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd9"
                              name="rd"
                            />
                            <label class="tab-label" for="rd9">
                              HEAVENLY CANDY Honey Sticks, fruity - Potency
                            </label>
                            <div class="tab-content">
                              <img
                                className="img-fluid"
                                src={hchoneysticksfruity}
                              />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd10"
                              name="rd"
                            />
                            <label class="tab-label" for="rd10">
                              HEAVENLY CANDY lavender hand lotion - Potency
                            </label>
                            <div class="tab-content">
                              <img
                                className="img-fluid"
                                src={hclavenderlotion}
                              />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd11"
                              name="rd"
                            />
                            <label class="tab-label" for="rd11">
                              HEAVENLY CANDY Mixed Party Pack - Potency
                            </label>
                            <div class="tab-content">
                              <img
                                className="img-fluid"
                                src={hcmixedpartypack}
                              />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd12"
                              name="rd"
                            />
                            <label class="tab-label" for="rd12">
                              HEAVENLY CANDY pain cream 900mg 2oz peppermint-
                              Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcpaincream} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd13"
                              name="rd"
                            />
                            <label class="tab-label" for="rd13">
                              HEAVENLY CANDY pet treats, chicken & apples (dog)
                              100mg- Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcpettreats} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd14"
                              name="rd"
                            />
                            <label class="tab-label" for="rd14">
                              HEAVENLY CANDY pet treats, chicken chews (cat)
                              100mg- Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcpettreats2} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd15"
                              name="rd"
                            />
                            <label class="tab-label" for="rd15">
                              HEAVENLY CANDY pet treats, large peanut butter
                              snacks (dog) 100mg- Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcpettreats3} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd16"
                              name="rd"
                            />
                            <label class="tab-label" for="rd16">
                              HEAVENLY CANDY pet treats, peanut butter buddies
                              (dog) 100mg- Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcpettreats4} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd17"
                              name="rd"
                            />
                            <label class="tab-label" for="rd17">
                              HEAVENLY CANDY popcorn - Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcpopcorn} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd18"
                              name="rd"
                            />
                            <label class="tab-label" for="rd18">
                              HEAVENLY CANDY sunscreen - Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcsunscreen} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd19"
                              name="rd"
                            />
                            <label class="tab-label" for="rd19">
                              HEAVENLY CANDY vegan jerky - Potency
                            </label>
                            <div class="tab-content">
                              <img className="img-fluid" src={hcveganjerky} />
                            </div>
                          </div>
                          <div class="tab">
                            <input
                              className="accordion"
                              type="radio"
                              id="rd99"
                              name="rd"
                            />
                            <label for="rd99" class="tab-close">
                              Close others &times;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LabReportsPage
